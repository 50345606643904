<template lang="pug">
  .card
    .card-body
      b-table(:items="users", :fields="fields", empty-text="No users to approve", show-empty)
        template(v-slot:cell(company_name)="data")
          | {{ data.item.company_name }}
          font-awesome-icon.fa-sm.text-success.ml-1(
            v-if="data.item.company_exist"
            :icon="iconMatch"
            v-b-tooltip="'Company found in database. User will be assigned to it after accepting.'"
          )
        
        template(v-slot:cell(linkedin_profile)="data")
          a(
            :href="'https://' + data.item.linkedin_profile"
            target="_blank"
            rel="noopener noreferrer"
            ) {{'https://' + data.item.linkedin_profile}}
        
        template(v-slot:cell(action)="data")
          b-button(variant="success" size="sm" @click="activateUser(data)") Accept
          b-button.ml-2(variant="danger" size="sm" @click="currentUser = data.item;showRejectUserModal = true") Reject

      b-modal(title="Reject user" v-model="showRejectUserModal" hide-footer)
        template(slot="default")
        p You are about to reject user:
        p <b>{{currentUser.first_name}} {{currentUser.last_name}}</b> 
        p Rejection reason :
        b-form-textarea.mt-2.mb-2( v-model="rejectionReason" rows='4' max-rows="6" placeholder="Please describe reject reason")

        .row
          .col-6
            b-button(variant="danger" @click="rejectUser" :disabled='rejectionReason.length === 0') Reject
            b-button.ml-2(variant="outline-secondary" @click="showRejectUserModal = false; currentUser = {}") Cancel


      b-button(variant="outline-secondary" size="sm" @click="fetchNonactivatedUsers") Refresh

</template>
<script>
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { createNamespacedHelpers } from 'vuex'
import { STORE_MODULE } from '@/enums'

const { mapState } = createNamespacedHelpers(STORE_MODULE.AUTH)

export default {
    name: 'ApproveRecruiters',

    created() {
        if (this.userAccount) this.fetchNonactivatedUsers()
    },

    computed: {
        ...mapState(['userAccount']),

        iconMatch: () => faCheckCircle,
    },

    watch: {
        userAccount: {
            handler() {
                this.fetchNonactivatedUsers()
            },
        },
    },

    data() {
        return {
            users: [],
            fields: [
                'first_name',
                'last_name',
                'email',
                'company_name',
                'company_website' /*,'matched_company'*/,
                'user_role',
                'linkedin_profile',
                'action',
            ],
            showRejectUserModal: false,
            currentUser: {},
            rejectionReason: '',
        }
    },

    methods: {
        fetchNonactivatedUsers() {
            this.$store.state.backend
                .get('/admin/users-to-approve')
                .then((res) => {
                    this.users = res.data
                })
                .catch((err) => alert('Error getting list of users'))
        },

        activateUser(row) {
            this.$store.state.backend
                .post('/admin/activate-user', { user_id: row.item.id })
                .catch((err) => alert(err))
                .finally(() => this.fetchNonactivatedUsers())
        },
        rejectUser() {
            this.showRejectUserModal = false
            this.$store.state.backend
                .post('/admin/reject-user', {
                    user_id: this.currentUser.id,
                    rejection_reason: this.rejectionReason,
                })
                .catch((err) => alert(err))
                .finally(() => {
                    this.currentUser = {}
                    this.rejectionReason = ''
                    this.fetchNonactivatedUsers()
                })
        },
        compareNames({ company_name, matched_company }) {
            let match = false
            if (company_name && matched_company)
                match = company_name.toLowerCase() === matched_company.toLowerCase()

            return match
        },
    },
}
</script>
